import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Container from "../components/containers/container";
import Box from "../components/containers/box";

const PrivacyPage = () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy</title>
      <meta
        name='description'
        content="Offriamo soluzioni per l'assistenza da remoto e per il telemonitoraggio in continuo degli impianti. I sistemi che utilizziamo prevedono connessioni Internet in modalità sicura, tramite VPN."
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='/image/social/og-teleassistenza-elettronica-scarpa.png' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Privacy Policy | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content="Offriamo soluzioni per l'assistenza da remoto e per il telemonitoraggio in continuo degli impianti. I sistemi che utilizziamo prevedono connessioni Internet in modalità sicura, tramite VPN."
      />
      <meta property='twitter:image' content='/image/social/og-teleassistenza-elettronica-scarpa.png' />
    </Helmet>
    <Herobanner background='assistenza'>
      <h1 className='mb-6'>Privacy Policy</h1>
    </Herobanner>

    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <p>
              Con la presente, si descrivono le modalità di gestione del sito titolarità di <strong>“Elettronica Scarpa SRL”</strong> denominato www.elettronicascarpa.it (d’ora innanzi il “Sito”) con
              riferimento al trattamento dei dati personali degli utenti che lo consultano (d’ora innanzi gli “Utenti”).{" "}
            </p>
            <p>
              La presente informativa, redatta ai sensi dell´art. 13 del Regolamento (UE) 2016/679 (d’ora innanzi il “GDPR”) e del D.Lgs 196/2003, così come novellato dal D.Lgs 101/2018 ((d’ora
              innanzi il "Codice in materia di protezione dei dati personali"), è destinata esclusivamente agli Utenti che navigano sul Sito e interagiscono con i servizi online ivi offerti,
              accessibili per via telematica a partire dal Sito.
            </p>
            <p>La consultazione del Sito comporta il rilascio di informazione e dati personali da parte dell’Utente.</p>
            <p>La validità della presente Informativa non si estende ad altri siti web di soggetti terzi, eventualmente consultabili mediante collegamento ipertestuale.</p>
            <br />
            <h3 className='h5'>1 - IL TITOLARE DEL TRATTAMENTO</h3>
            <br />
            <p>Il titolare del trattamento è Elettronica Scarpa SRL, con sede legale ed operativa ubicata a Via Castellengo 42/A, 13836 Cossato (BI) (d’ora innanzi il “Titolare”).</p>
            <br />
            <h3 className='h5'>2 - LUOGO DI TRATTAMENTO DEI DATI</h3>
            <br />
            <p>I trattamenti connessi ai servizi forniti online dal Sito hanno luogo presso la predetta sede del Titolare e possono essere eseguiti:</p>
            <p>
              a. da soggetti che agiscono tipicamente in qualità di responsabili del trattamento ex art. 28 del Regolamento ossia: <br />
              i) persone, società o studi professionali che prestano attività di assistenza e consulenza a Elettronica Scarpa SRL in materia contabile, amministrativa, legale, tributaria, finanziaria
              e di recupero crediti relativamente alla erogazione dei Servizi; <br />
              ii) soggetti con i quali sia necessario interagire per l’erogazione dei Servizi, quali, ad esempio, gli hosting provider;
              <br />
              iii) soggetti delegati a svolgere attività di manutenzione tecnica, inclusa la manutenzione degli apparati di rete e delle reti di comunicazione elettronica (collettivamente i
              “Destinatari”);
            </p>
            <p>l’elenco dei responsabili del trattamento che trattano dati può essere richiesto al Titolare.</p>
            <p>
              b. da soggetti, enti od autorità, autonomi titolari del trattamento, a cui sia obbligatorio comunicare i dati personali degli Utenti in forza di disposizioni di legge o di ordini delle
              autorità;
            </p>
            <p>c. dal personale tecnico/amministrativo incaricato del trattamento come da art. 29 del GDPR.</p>
            <br />
            <h3 className='h5'>3 - TIPOLOGIA DEI DATI TRATTATI.</h3>
            <br />
            <p>Il trattamento può avere ad oggetto la seguente tipologia di dato:</p>
            <p>
              i) <strong>Dati di Navigazione</strong>
              <br />I sistemi informatici preposti al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei
              protocolli di comunicazione interna, trattandosi di informazioni che non sono raccolte per identificare gli Utenti ma che per loro stessa natura potrebbero, mediante elaborazioni ed
              associazioni con dati detenuti da terzi, permettere di identificare gli utenti. Tale categoria di dati comprende gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente
              che si connette al Sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta
              al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed e altri parametri relativi al
              sistema operativo e all’ambiente informatico dell’utente. I dati, così raccolti, potrebbero essere utilizzati per l’accertamento di responsabilità in caso di eventuali reati informatici
              ai danni del Sito.
              <br />
              ii) <strong>Dati sensibili</strong>
              <br />
              Eventuali dati sensibili, di cui all’art. 9.1 del GDPR non dovrebbero essere trattati se non previo consenso esplicito reso da parte dell’interessato
            </p>
            <br />
            <h3 className='h5'>4 - FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO</h3>
            <br />
            <p>
              <strong>4.1 Finalità</strong>
            </p>
            <p>I dati personali degli Utenti sono trattati nella fattispecie seguente:</p>
            <p>a. per registrarsi al Sito e rendere possibile l’erogazione dei servizi ivi offerti;</p>
            <p>b. per una richiesta generica di informazioni;</p>
            <p>c. per rispondere a richieste di assistenza o di informazioni;</p>
            <p>d. per far valere o difendere diritti in giudizio, in caso di abusi nell’utilizzo del Sito e/o dei nostri Servizi o per contenziosi di natura contrattuale o extracontrattuale;</p>
            <p>e. per assolvere eventuali obblighi di legge, contabili e fiscali.;</p>
            <p>f. per rendere possibile l´esecuzione degli obblighi legali previsti.</p>
            <br />
            <p>
              <strong>4.2 Base giuridica</strong>
            </p>
            <p>
              <strong>4.2.1</strong> La base legale del trattamento di dati personali degli Utenti per le finalità di cui all’art. 4.1., lett. a), b), c), d) è l’art. 6.1.b) del GDPR, in quanto i
              trattamenti sono necessari all’erogazione dei Servizi o per il riscontro di richieste dell’interessato. Il conferimento dei dati personali degli Utenti per queste finalità è facoltativo
              ma l’eventuale mancato conferimento comporterebbe l’impossibilità di attivare i Servizi forniti dal Sito.
            </p>
            <p>
              <strong>4.2.2</strong> La finalità di cui all’art. 4.1., lett. f) rappresenta un trattamento di dati personali degli Utenti svolto ai sensi dell’art. 6.1.c) del GDPR, per l’adempimento a
              un obbligo legale al quale il Titolare è soggetto. Il trattamento dei dati personali raccolti con la compilazione di un modulo per richiesta di contatto e forniti per le finalità di cui
              all’ art. 4.1., lett. c) (rispondere a richieste di assistenza o di informazioni) verrà svolto nel rispetto delle condizioni di liceità ex art. 6 lett. f) (legittimo interesse) del GDPR.
            </p>
            <br />
            <h3 className='h5'>5 - MODALITÀ E DURATA DEL TRATTAMENTO</h3>
            <br />
            <p>
              I dati personali verranno trattati per mezzo di strumenti informatico/telematici e per finalità connesse e/o strumentali ai servizi online forniti sul Sito o per la semplice navigazione
              sul medesimo e, in particolare, , nel rispetto dei principi di correttezza, liceità e trasparenza come previsto dal GDPR e per le finalità in particolare:
            </p>
            <p>
              Il trattamento sarà svolto in forma automatizzata e manuale, con modalità e strumenti volti a garantire la massima sicurezza e riservatezza dei dati, ad opera di soggetti di ciò
              appositamente incaricati.
            </p>
            <p>
              {" "}
              Nel rispetto di quanto previsto dall’art. 5, comma 1 lett. e) del GDPR, i dati personali raccolti verranno conservati in una forma che consenta l’identificazione degli interessati per un
              arco di tempo non superiore al conseguimento delle finalità per le quali i dati personali sono trattati. I dati di tipo tecnico e i dati forniti volontariamente dall’Utente saranno
              trattati e conservati nel rispetto delle misure di sicurezza previste dal GDPR per il tempo strettamente necessario all’erogazione dei servizi forniti sul Sito. I dati personali raccolti
              non saranno eccedenti e conservati per il periodo di tempo necessario agli scopi per i quali sono stati raccolti, nel rispetto dei principi di minimizzazione e limitazione della
              conservazione ex art. 5.1.e) del GDPR.
            </p>
            <p> I dati personali sono conservati all’interno dell’Unione Europea. </p>
            <p>
              Eventuali Responsabili del Trattamento verranno nominati, all’atto del conferimento da parte del Titolare di incarichi esterni per lo svolgimento dei quali è necessario condividere i
              dati dell’Utente, tra coloro che presentino garanzie sufficienti per mettere in atto misure tecniche e organizzative adeguate in modo tale che il trattamento soddisfi i requisiti del
              GDPR e garantisca la tutela dei diritti dell’interessato e previa sottoscrizione da parte del Responsabile di apposito contratto/addendum all’incarico professionale contenente i
              requisiti e le specifiche del trattamento stesso.
            </p>
            <br />
            <h3 className='h5'>6 - DIRITTI DELL’INTERESSATO.</h3>
            <br />
            <p>
              L’Utente potrà esercitare in qualsiasi momento i diritti previsti dagli articoli 15 a 22 e dall’art. 34 del GDPR. In particolare, l’Utente ha il diritto di chiedere a Elettronica Scarpa
              SRL l'accesso ai dati personali che lo riguardano, nonché la rettifica, la cancellazione, la limitazione, l’opposizione al loro trattamento e la portabilità degli stessi, ove trattati in
              modo automatizzato sulla base del consenso o per l’esecuzione del contratto.
            </p>
            <p>
              {" "}
              Inoltre, l’Utente potrà in esercitare in ogni momento il diritto di revocare il consenso prestato per una o più finalità senza con questo pregiudicare la liceità del trattamento, basata
              sul consenso prestato prima della revoca.
            </p>{" "}
            <p>L’Utente ha il diritto di proporre reclamo all'Autorità Garante per il trattamento dei dati personali, ai sensi dell’art. 77 del GDPR UE 679/2016.</p>
            <br />
            <h3 className='h5'>7 - MODALITA’ DI ESERCIZIO DEI DIRITTI ex art 12 del GDPR</h3>
            <br />
            <p>
              L’interessato potrà in qualsiasi momento esercitare i diritti individuati dall’art. 15 e, ove applicabili, dagli art.16-21, inviando una raccomandata a.r. presso la sede legale del
              Titolare sita Via Castellengo 42/A 13836 Cossato (BI) ovvero scrivendo all’indirizzo di posta elettronica info@elettronicascarpa.it.
            </p>
            <br />
            <br />
            <strong>
              Gli Utenti sono pregati di consultare periodicamente e con attenzione questa pagina al fine di verificare eventuali aggiornamenti o revisioni che si dovessero rendere necessari anche al
              fine di recepire e/o conformarsi a normative nazionali, comunitarie, internazionali o per adeguarsi ad intervenute innovazioni tecnologiche.
            </strong>
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
          </div>
        </div>
      </Box>
    </Container>
  </Layout>
);

export default PrivacyPage;
